<template>
	<div>
		<div class="js-copy js-content pd_body" @scroll="handleScroll">
			<topHeader></topHeader>
			<acSuccess
				v-if="showSuccess"
				@showNext="showNext()"
				:tipTitle="tipTitle"
				:tipMessage="tipMessage"
			>
			</acSuccess>
			<div class="ac_detail_top">
				<img :src="item.pic" />
			</div>

			<div class="ac_ticket_content">
				<div class="ac_ticket_content_title">
					{{ item.title }}
				</div>
				<div class="ac_ticket_content_box">
					<div class="ac_ticket_content_online">
						{{ item.type == 5 ? "线上活动" : "线下活动" }}
					</div>
					<div class="ac_ticket_content_host" v-if="item.ticket_count > 0">
						<div class="ac_ticket_content_host_header">
							<div v-for="(pic, index) in headers" :key="index">
								<img
									class="ac_people_img"
									v-if="index < item.ticket_count"
									:src="pic"
								/>
							</div>
						</div>
						<div class="ac_ticket_content_host_num"
							>{{ item.ticket_count }}+ PARTICIPANTS</div
						>
					</div>
				</div>
				<div class="ac_ticket_content_box">
					<div class="ac_ticket_content_addtess">
						<img src="https://zy.metaera.media/assets/images/active/15_1.png" />
						<span>{{ item.address }}</span>
					</div>
					<div class="ac_ticket_content_time">
						<img src="https://zy.metaera.media/assets/images/active/16.png" />
						<span>{{ item.start_time }} 至 {{ item.end_time }}</span>
					</div>
					<div
						class="ac_ticket_content_url pointer"
						v-if="item.url != undefined && item.url.length > 0"
						@click="showUrl(item.url)"
					>
						<img src="https://zy.metaera.media/assets/images/active/17.png" />
						<span style="color: #0256ff">{{ item.url }}</span>
					</div>
				</div>

				<div class="ac_ticket_overview_content">
					<div class="ac_ticket_overview_content_item">
						<p class="ql-editor" v-html="item.content"></p>
					</div>
				</div>
			</div>
			<div class="ac_ticket_select">
				<div class="ac_ticket_select_title">申请者信息</div>
				<div class="ac_ticket_select_user">
					<img
						:src="
							$store.state.user.avatar && $store.state.user.avatar.length > 0
								? $store.state.user.avatar
								: 'https://zy.metaera.media/assets/images/tab/tab_mine_b1.png'
						"
					/>
					<span>{{ $store.state.user.name }}</span>
				</div>
				<div class="ac_ticket_list">
					<div
						class="ac_ticket_item"
						v-for="(item, index) in ticketArray"
						:key="index"
						:class="{ ac_ticket_item_sel: index == ticket_sel_index }"
					>
						<img
							:src="
								item.type == 0
									? 'https://zy.metaera.media/assets/images/active/48_2.png'
									: item.type == 1
									? 'https://zy.metaera.media/assets/images/active/47_2.png'
									: 'https://zy.metaera.media/assets/images/active/57_2.png'
							"
							@click="ticketClick(index)"
						/>
						<span class="ac_ticket_item_type" @click="ticketClick(index)"
							>{{
								item.type == 0 ? "免费票" : item.type == 1 ? "折扣票" : "全价票"
							}}
						</span>

						<span class="ac_ticket_item_name" @click="ticketClick(index)"
							>{{ item.name }}
						</span>
					</div>
				</div>

				<div class="ac_ticket_select_num">
					<div class="ac_ticket_select_item">
						<div class="ac_ticket_select_item_text">购买数量</div>
						<el-input
							placeholder="请输入活动邀请码"
							v-model="count"
							@input="handleInput"
						>
						</el-input>
					</div>

					<div
						class="ac_ticket_select_item"
						v-if="
							this.ticketArray.length > this.ticket_sel_index &&
							this.ticketArray[this.ticket_sel_index].type == 1
						"
					>
						<div class="ac_ticket_select_item_text">输入邀请码</div>
						<el-input placeholder="请输入活动邀请码" v-model="code"> </el-input>
					</div>
				</div>

				<div class="ac_ticket_select_price">
					<div class="ac_ticket_select_price_title">总金额</div>
					<div class="ac_ticket_select_price_amount"
						>{{
							this.ticketArray.length > this.ticket_sel_index
								? this.ticketArray[this.ticket_sel_index].price * this.count
								: "0"
						}}
						USD</div
					>
				</div>
				<div class="ac_ticket_select_check">
					<img
						src="https://zy.metaera.media/assets/images/active/25.png"
						@click="onCheckClick()"
						v-if="onCheck == 1"
					/>
					<img
						v-else
						src="https://zy.metaera.media/assets/images/active/24.png"
						@click="onCheckClick()"
					/>
					<span class="ac_ticket_select_check_text">
						通过确认订阅，您同意根据他们的条款从您的卡中扣除未来的付款。下订单即表示您同意我们的
						<span
							class="ac_ticket_select_check_text_item pointer"
							@click="gotoPrivacy()"
						>
							服务条款
						</span>
						和
						<span
							class="ac_ticket_select_check_text_item pointer"
							@click="gotoPrivacy()"
						>
							隐私政策
						</span>
						.
					</span>
				</div>
				<div class="ac_ticket_option">
					<div class="ac_ticket_option_item pointer" @click="goBack()"
						>上一步</div
					>
					<div class="ac_ticket_option_item pointer" @click="next()">
						确定
					</div>
				</div>
			</div>
			<div style="height: 110px"></div>
			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import { getToken, getUserMobile } from "@/utils/auth";
import store from "@/store";
import { acitvityDetail, acitvityOrder } from "@/api/home";
import topHeader from "../../components/pc/topHeader.vue";
import bottom from "../../components/pc/bottom.vue";
import { getUserId } from "@/utils/auth";
import acSuccess from "./ac_success.vue";
import { loadStripe } from "@stripe/stripe-js";

export default {
	name: "",
	components: {
		topHeader,
		bottom,
		acSuccess,
	},
	data() {
		return {
			token: getToken(),
			item: {},
			loading: false,
			id: "",
			showSuccess: false,
			tipTitle: "活动参与成功",
			tipMessage: "",
			ticket_sel_index: 0,
			ticketArray: [],
			count: 1,
			code: "",
			onCheck: 0,
			headers: [
				"https://zy.metaera.media/assets/images/active/51.png",
				"https://zy.metaera.media/assets/images/active/52.png",
				"https://zy.metaera.media/assets/images/active/53.png",
				"https://zy.metaera.media/assets/images/active/54.png",
				"https://zy.metaera.media/assets/images/active/55.png",
			],
		};
	},
	mounted() {
		this.token = getToken();
	},
	created() {
		this.token = getToken();
		this.id = this.$route.query.id;
		this.getArticleDetail();
	},
	methods: {
		gotoPrivacy() {
			this.$router.push({
				path: "/copyright",
			});
		},
		handleInput(value) {
			// 验证输入的值是否为正整数
			console.log(value);

			const num = Number(value);
			if (!isNaN(num) && Number.isInteger(num) && num > 0) {
				this.count = num.toString();
			} else {
				this.count = "";
			}
		},
		onCheckClick() {
			this.onCheck = this.onCheck == 0 ? 1 : 0;
		},
		showNext() {
			if (this.ticketArray[this.ticket_sel_index].type == 0) {
				this.$router.push({
					path: "/ac_mine",
				});
			}
		},
		next() {
			if (this.ticketArray[this.ticket_sel_index].type == 0) {
				this.freeTicket();
			} else {
				this.buyTicket();
			}
		},
		ticketClick(index) {
			this.ticket_sel_index = index;
		},
		goBack() {
			if (window.history.length > 1) {
				this.$router.go(-1);
			} else {
				this.$router.push("/");
			}
		},
		async buyTicket() {
			if (this.onCheck != 1) {
				this.$message.error("请阅读并勾选隐私协议");
				return;
			}
			if (this.count < 1) {
				this.$message.error("请正确输入购票数量");
				return;
			}

			let ticketItem = this.ticketArray[this.ticket_sel_index];
			if (ticketItem["type"] == 1 && this.code.length == 0) {
				this.$message.error("请正确输入折扣码");
				return;
			}
			const stripe = await loadStripe(
				"pk_live_51PYrHdEQq0jGyNkS55zMD21yxNNrl2pZU9nlo2IxhgKcbEgVWQGRyjWhB3pEzzgrDwTxohG54aairByrxCqIg32g00gWroSP0b"
			); // 替换为你的Publishable Key
			let obj = {
				id: this.id,
				type: ticketItem["type"],
				count: this.count,
				code: this.code,
				userid: getUserId(),
			};
			this.$loading.show();
			console.log(obj);

			acitvityOrder(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						let ticketId = res.data.ticketId;
						if (ticketId) {
							this.$message({ message: "success", type: "success" });
							this.$router.push({
								path: "/ac_mine",
							});
						} else {
							stripe.redirectToCheckout({
								sessionId: res.data.checkout_session,
							});
						}
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message.error(error.message);
				});
		},
		freeTicket() {
			if (this.onCheck != 1) {
				this.$message.error("请阅读并勾选隐私协议");
				return;
			}
			if (this.count < 1) {
				this.$message.error("请正确输入购票数量");
				return;
			}
			let obj = {
				id: this.id,
				type: 0,
				count: this.count,
				userid: getUserId(),
			};
			this.$loading.show();
			acitvityOrder(obj)
				.then((res) => {
					this.$loading.hide();
					if (res.code == 200) {
						this.showSuccess = true;
					} else {
						this.$message.error(res.message);
					}
				})
				.catch((error) => {
					this.$loading.hide();
					this.$message.error(error.message);
				});
		},
		login() {
			if (this.token == 0 || this.token == undefined) {
				return false;
			} else {
				return true;
			}
		},
		handleScroll(event) {},
		showUrl(url) {
			if (url == undefined || url.length == 0) {
				return;
			}
			window.open(url, "");
		},
		getArticleDetail() {
			let obj = { id: this.id };
			acitvityDetail(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						this.item = res.data;
						let tmp = JSON.parse(this.item.ticket);
						// for (let index = 0; index < tmp.length; index++) {
						// 	const element = tmp[index];
						// 	let isHave = false;
						// 	for (let num = 0; num < this.ticketArray.length; num++) {
						// 		const tmpElement = this.ticketArray[num];
						// 		if (tmpElement.type == element.type) {
						// 			isHave = true;
						// 		}
						// 	}
						// 	if (!isHave) {
						// 		this.ticketArray.push(element);
						// 	}
						// }
						this.ticketArray = tmp;
						this.ticket_sel_index = 0;
					} else {
						this.$message.error("获取活动详情失败，请重新获取");
						this.goBack();
					}
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
		formatDate(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString("zh-CN", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: false,
			});
		},
	},
};
</script>

<style scoped>
.pd_body {
	height: 100vh;
	overflow-y: scroll;
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: white;
}
</style>

<!-- ac_detail_top -->
<style scoped>
.ac_detail_top {
	margin-top: 100px;
	width: 100%;
	background: url(https://zy.metaera.media/assets/images/active/13.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
	position: relative; /* 为了让伪元素相对于这个容器定位 */
}
.ac_detail_top img {
	margin-top: 80px;
	margin-bottom: 80px;
	width: 60%;
	margin-left: 20%;
	border-radius: 20px;
}
</style>

<!-- ac_ticket_content -->
<style scoped>
.ac_ticket_content {
	width: 80%;
	margin-left: 10%;
	margin-top: 70px;
}

.ac_ticket_content_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 30px;
	font-weight: 400;
	line-height: 40px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.ac_ticket_content_box {
	display: flex;
	margin-top: 10px;
	align-items: center;
}

.ac_ticket_content_online {
	height: 30px;
	border-radius: 10px;
	/* 333 */
	background: white;
	padding-left: 14px;
	padding-right: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 14px;
	display: flex;
	align-items: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #333333;
	border: 1px solid #333333;
}

.ac_ticket_content_host {
	display: flex;
	/* 正文色/正文色 */
	color: #1a1a1a;
	align-content: center;
	text-align: center;
	align-items: center;
}
.ac_ticket_content_host_header {
	margin-left: 20px;
	display: flex;
}

.ac_ticket_content_host_header img {
	box-sizing: border-box;
	border: 0.5px solid #ffffff;
	width: 18px;
	height: 18px;
	opacity: 1;
	border-radius: 9px;
	margin-right: -10px;
}

.ac_ticket_content_host_num {
	display: flex;
	/* 正文色/正文色 */
	color: #1a1a1a;
	align-content: center;
	text-align: center;
	align-items: center;
	margin-left: 20px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 10px;
	font-weight: normal;
	line-height: 10px;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
}
.ac_ticket_content_addtess img,
.ac_ticket_content_time img,
.ac_ticket_content_url img {
	height: 16px;
	width: auto;
}

.ac_ticket_content_addtess span,
.ac_ticket_content_time span,
.ac_ticket_content_url span {
	margin-right: 30px;
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 正文色/正文色 */
	color: #1a1a1a;
}
</style>

<!-- ac_detail_overview -->
<style scoped>
.ac_ticket_overview_content {
	margin-top: 20px;

	border-radius: 20px;
	background: rgba(216, 216, 216, 0.3);
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.ac_ticket_overview_content_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: justify;
	font-variation-settings: "opsz" auto;
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	line-height: 20px;
}

.ql-editor {
	max-height: 100px;
	font-variation-settings: "opsz" auto;
	/* 正文色/正文辅助色 */
	color: #767676;
	overflow: hidden; /* 隐藏超出容器的内容 */
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 4; /* 限制最多显示两行 */
	white-space: normal; /* 允许换行 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	line-height: 1.5em; /* 设置行高 */
	padding: 0 0;
}
</style>

<!-- ac_ticket_select -->
<style scoped>
.ac_ticket_select {
	width: 80%;
	margin-left: 10%;
	margin-top: 70px;
}

.ac_ticket_select_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 28px;
	font-weight: normal;
	line-height: 28px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #1a1a1a;
	text-decoration: underline;
	text-decoration-color: #0256ff; /* 设置下划线颜色 */
	text-decoration-thickness: 4px; /* 设置下划线高度 */
	text-underline-offset: 10px; /* 调整下划线与文字的距离 */
}

.ac_ticket_select_user {
	display: inline-flex;
	padding-right: 20px;
	margin-top: 35px;
	height: 52px;
	border-radius: 13px;
	/* 正文色/正文色 */
	background: rgba(26, 26, 26, 0.1);
}

.ac_ticket_select_user img {
	margin-left: 13px;
	margin-top: 6px;
	width: 40px;
	height: 40px;
	opacity: 1;
	border-radius: 20px;
}

.ac_ticket_select_user span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	letter-spacing: 0em;
	align-content: center;
	align-items: center;
	font-variation-settings: "opsz" auto;
	/* 黑色主色（字段一级） */
	color: #333333;
	display: flex;
}
.ac_ticket_list {
	margin-top: 30px;
	display: flex;
}

.ac_ticket_item {
	position: relative; /* 设置相对定位 */
	display: inline-block; /* 使每个项目成为行内块元素 */
	margin-right: clamp(20px, calc(100vw * 40 / 1280), 80px);
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 8px;
	padding-bottom: 8px;
	border-radius: 14px;
	border: 1px solid rgba(26, 26, 26, 0);
}

.ac_ticket_item_type {
	position: absolute; /* 设置绝对定位 */
	width: 12vw;
	bottom: 55%; /* 将文字放置在图片的底部 */
	left: 8%; /* 将文字水平居中 */
	color: white; /* 设置文字颜色 */
	padding: 5px 10px; /* 设置内边距 */
	border-radius: 5px; /* 设置圆角 */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 14 / 1280), 20px);
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 2; /* 限制最多显示两行 */
	overflow: hidden; /* 隐藏超出容器的内容 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	white-space: normal; /* 允许换行 */
}

.ac_ticket_item_name {
	position: absolute; /* 设置绝对定位 */
	width: 12vw;
	bottom: 20%; /* 将文字放置在图片的底部 */
	left: 8%; /* 将文字水平居中 */
	color: white; /* 设置文字颜色 */
	padding: 5px 10px; /* 设置内边距 */
	border-radius: 5px; /* 设置圆角 */
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 12 / 1280), 20px);
	display: -webkit-box; /* 使用 Webkit 的盒模型 */
	-webkit-box-orient: vertical; /* 垂直排列子元素 */
	-webkit-line-clamp: 2; /* 限制最多显示两行 */
	overflow: hidden; /* 隐藏超出容器的内容 */
	text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
	white-space: normal; /* 允许换行 */
}

.ac_ticket_item_sel {
	border: 1px solid #1a1a1a;
}
.ac_ticket_item img {
	display: block; /* 确保图片是块级元素 */
	height: clamp(80px, calc(100vw * 80 / 1280), 140px);
	width: auto;
}

.ac_ticket_option {
	margin-top: 80px;
	display: flex;
	justify-content: center;
}

.ac_ticket_option_item {
	margin-left: 25px;
	margin-right: 25px;
	width: 320px;
	height: 54px;
	border-radius: 10px;
	opacity: 1;

	/* 主要色/主要色 */
	background: #0256ff;

	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: normal;
	line-height: 16px;
	text-align: center;
	align-items: center;
	align-content: center;
	/* text-transform: uppercase; */
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #ffffff;
	display: grid;
}

.ac_ticket_select_num {
	display: flex;
	margin-top: 30px;
}
.ac_ticket_select_item {
	margin-right: 50px;
}
.ac_ticket_select_item_text {
	margin-bottom: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_price {
	margin-top: 40px;
}
.ac_ticket_select_price_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_price_amount {
	margin-top: 15px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 50px;
	font-weight: 500;
	line-height: 50px;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 主要色/主要色 */
	color: #0256ff;
}

.ac_ticket_select_check {
	margin-top: 20px;
}

.ac_ticket_select_check img {
	width: 14px;
	height: 14px;
}
.ac_ticket_select_check_text {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	font-size: 12px;
	font-variation-settings: "opsz" auto;
	color: #333333;
}

.ac_ticket_select_check_text_item {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 12px;
	font-variation-settings: "opsz" auto;
	color: #333333;
	text-decoration: underline;
	text-decoration-color: #333333; /* 设置下划线颜色 */
	text-decoration-thickness: 2px; /* 设置下划线高度 */
	text-underline-offset: 6px; /* 调整下划线与文字的距离 */
}
</style>
<style></style>
