<template>
	<div class="pc_bottom">
		<div class="pc_bottom_content">
			<div class="pc_bottom_content_logo">
				<img src="https://zy.metaera.media/assets/images/event/34.png" />
			</div>
			<div class="pc_bottom_content_right">
				<div class="pc_bottom_content_product">
					<div class="pc_bottom_content_title">产品</div>
					<div
						class="pc_bottom_content_btn pointer"
						@click="toPath('/home_kuaixun')"
					>
						快讯
					</div>
					<div
						class="pc_bottom_content_btn pointer"
						@click="toPath('/home_zl')"
					>
						专栏
					</div>
					<div class="pc_bottom_content_btn pointer" @click="toPath('/events')">
						活动
					</div>
				</div>
				<div class="pc_bottom_content_explore">
					<div class="pc_bottom_content_title">探索</div>
					<div
						class="pc_bottom_content_btn pointer"
						@click="toPath('/gw_search')"
					>
						搜索
					</div>
					<div class="pc_bottom_content_btn" @click="toPath()"> 下载 </div>
					<div class="pc_bottom_content_btn">
						<img
							src="https://zy.metaera.media/assets/images/aboutus/download.png"
							alt=""
						/>
					</div>
				</div>
				<div class="pc_bottom_content_company">
					<div class="pc_bottom_content_title">公司</div>
					<div
						class="pc_bottom_content_btn pointer"
						@click="toPath('/aboutus')"
					>
						关于我们
					</div>
					<div
						class="pc_bottom_content_btn pointer"
						@click="toPath('/aboutus')"
					>
						联系我们
					</div>
				</div>
			</div>
		</div>
		<div class="pc_bottom_bottom">
			<div class="pc_bottom_bottom_left">
				<div class="pc_bottom_bottom_left_title">
					© 2022 Welcome. All right reserved.
				</div>
				<div class="pc_bottom_bottom_left_title pointer" @click="gotoPrivacy()"
					>Privacy Policy</div
				>
				<div class="pc_bottom_bottom_left_title pointer" @click="gotoPrivacy()"
					>Terms of Service</div
				>
			</div>
			<div class="pc_bottom_bottom_right">
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/9.png"
					@click="showUrl('https://www.youtube.com/@MetaEraCN')"
				/>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/10.png"
					@click="showUrl('https://x.com/MetaEraCN')"
				/>
				<img
					class="pointer"
					src="https://zy.metaera.media/assets/images/active/11.png"
					@click="
						showUrl(
							'https://www.linkedin.com/company/metaerahk/?originalSubdomain=hk'
						)
					"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	props: {},
	mounted() {},
	created() {},
	data() {
		return {
			token: 0,
		};
	},
	methods: {
		toPath(router) {
			this.$router.push({ path: router });
		},
		showUrl(url) {
			window.open(url, "");
		},
		gotoPrivacy() {
			this.$router.push({
				path: "/copyright",
			});
		},
	},
	watch: {},
};
</script>
<style scoped>
.pc_bottom {
	width: 100%;
	height: 385px;
	padding-top: 110px;
	background: black;
}

.pc_bottom_content {
	width: 100%;

	display: flex;
	justify-content: space-between;
}
.pc_bottom_content_logo {
	margin-left: 90px;
	width: 310px;
}
.pc_bottom_content_logo img {
	width: 100%;
	height: auto;
}
.pc_bottom_content_right {
	display: flex;
	width: 50%;
}
.pc_bottom_content_product,
.pc_bottom_content_explore,
.pc_bottom_content_company {
	width: 33%;
}
.pc_bottom_content_title {
	margin-bottom: 27px;
	font-family: Inter;
	font-size: 13px;
	font-weight: normal;
	line-height: 18.2px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	color: rgba(255, 255, 255, 0.65);
}

.pc_bottom_content_btn {
	margin-bottom: 24px;
	font-family: AlibabaPuHuiTi;
	font-size: 20px;
	font-weight: normal;
	line-height: 16px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.pc_bottom_content_btn img {
	width: 90px;
	height: auto;
}

.pc_bottom_bottom {
	width: 100%;
	margin-top: 80px;
	display: flex;
	justify-content: space-between;
}

.pc_bottom_bottom_left {
	margin-left: 80px;
	display: flex;
}
.pc_bottom_bottom_left_title {
	margin-right: 25px;
	font-family: Inter;
	font-size: 13px;
	font-weight: normal;
	line-height: 18.2px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	color: rgba(255, 255, 255, 0.65);
}

.pc_bottom_bottom_right {
	display: flex;
	width: 20px;
	height: 20px;
	margin-right: 180px;
}

.pc_bottom_bottom_right img {
	width: 100%;
	height: 100%;
	margin-right: 16px;
	/* margin-left: 16px; */
}
</style>
